#pagination {
  float: right;
}

#pagination ul {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}

#pagination li {
  display: inline-block;
  padding: 6px 0;
  border: 1px solid rgba(128, 128, 128, 0.705);
}

#pagination li a {
  padding: 7px 15px;
}

#pagination .active {
  color: #5046e4;
  border: 1px solid #5046e4;
  background-color: #cbccfa54;
}

#pagination .next {
  border-radius: 0 5px 5px 0;
}

#pagination .previous {
  border-radius: 5px 0 0 5px;
}
#pagination :where(.next, .previous).disabled a {
  color: gray;
  cursor: not-allowed;
}

:where(#table-scroll, #tab-scroll)::-webkit-scrollbar {
  width: 0;
  height: 10px;
  border: 1px solid #fff;
}

#tab-scroll::-webkit-scrollbar {
  width: 0px;
  height: 4px;
  border: 1px solid #fff;
}

.vertical-scroll::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: inherit;
  border-color: inherit;
}

:where(#table-scroll, #tab-scroll, .vertical-scroll)::-webkit-scrollbar-track {
  border-radius: 20px;
  background-color: inherit;
  border-color: inherit;
}

:where(#table-scroll, #tab-scroll, .vertical-scroll)::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #b0b0b0;
}

/* class name given by quill editor */

.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}

.ql-align-justify {
  text-align: justify;
}

.ql-indent-1 {
  text-indent: 3em;
}

.ql-indent-2 {
  text-indent: 6em;
}

.ql-indent-3 {
  text-indent: 9em;
}

.ql-indent-4 {
  text-indent: 12em;
}

.ql-indent-5 {
  text-indent: 15em;
}

.ql-indent-6 {
  text-indent: 18em;
}

.ql-indent-7 {
  text-indent: 21em;
}

.ql-indent-8 {
  text-indent: 24em;
}

.ql-indent-9 {
  text-indent: 27em;
}

.editor-style ul {
  list-style-type: disc;
  padding-left: 1rem;
}

.editor-style ol {
  list-style-type: decimal;
  padding-left: 1rem;
}

.editor-style h1 {
  font-size: 2em;
}

.editor-style h2 {
  font-size: 1.5em;
}

.editor-style h3 {
  font-size: 1.2em;
}

.editor-style a {
  text-decoration: underline;
  cursor: pointer;
}
