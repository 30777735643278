@tailwind base;
@tailwind components;
@tailwind utilities;

#g-recaptcha > div {
  margin: 0 auto;
}

/* hide number input type spin button */

/* Chorme */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance:textfield;
    appearance: textfield;
}
